.programa-card {
 
  width: 450px;
  padding: 0;
  overflow: hidden; /* Evita que a imagem exceda as bordas arredondadas do card */
  border-radius: 0.25rem; /* Bordas arredondadas para o card */
}

.cursos-disponiveis {
  background-color: #f8f9fa; /* Cor de fundo clara */
  border: 1px solid #dee2e6; /* Borda suave */
  padding: 20px; /* Espaçamento interno */
  border-radius: 8px; /* Bordas arredondadas */
  transition: transform 0.2s; /* Transição suave para o efeito hover */
}


.cursos-disponiveis .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #14253A; /* Cor de fundo do ícone */
  color: white;
  margin: 0 auto 15px; /* Margens para centralizar e espaçamento */
}

.cursos-disponiveis h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

.cursos-disponiveis .display-4 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.editar-perfil {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}


.editar-perfil .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #14253A;
  color: white;
  margin: 0 auto 15px;
}

.editar-perfil h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

.certificados {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}



.certificados .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #14253A;
  color: white;
  margin: 0 auto 15px;
}

.colunas-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-top: 30px; /* Espaçamento entre o título e as colunas */
}

.cursos-disponiveis,
.editar-perfil,
.certificados {
  flex: 1;
  margin: 5px;
  display: flex; /* Habilita o flexbox nas colunas */
  flex-direction: column; /* Organiza os elementos da coluna verticalmente */
  justify-content: space-between; /* Distribui o espaço entre os elementos da coluna */
}

.cursos-disponiveis .btn-suus,
.editar-perfil .btn-suus,
.certificados .btn-suus {
  margin-top: auto; /* Empurra o botão para a parte inferior da coluna */
}

@media (max-width: 768px) {
  .colunas-container {
    
    flex-direction: column;
    
  }

  .cursos-disponiveis,
  .editar-perfil,
  .certificados {
    margin-bottom: 10px;
  }
}

.periodo-acesso-explicacao {
  background-color: #f9f9f9; /* Cor de fundo suave para destacar */
  border-left: 4px solid #ff9900; /* Barra lateral para destaque */
  padding: 10px; /* Espaçamento interno */
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 14px; /* Tamanho da fonte */
  color: black; /* Cor da fonte */
  font-family: 'Montserrat';
  margin-bottom: 20px;
 
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff; /* cor do check */
  border-color: #ff7f00; /* cor da borda do checkbox */
  background-color: #ff7f00; /* cor de fundo do checkbox */
}
.floating-cart-btn {
  position: sticky;
  bottom: 14px;
  right: 20px;
  z-index: 1000;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background-color: #1E2A38;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-count {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coluna-apresentacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #555;
  padding: 25px;


}

.custom-control-label::before {
  border-radius: 0.25rem; /* bordas arredondadas para o checkbox */
}

.programa-card img {
  width: 100%; /* A imagem ocupa toda a largura do card */
  height: 180px; 
  object-fit: cover; /* Garante que a imagem cubra todo o espaço disponível, sendo cortada se necessário */
  border-radius: 0.25rem 0.25rem 0 0; /* Arredonda apenas as bordas superiores da imagem */
}



.valores-cursos p {
  margin: 5px 0; /* Adiciona um pequeno espaçamento entre os valores */
  font-size: 14px; /* Tamanho da fonte para os valores */
  color: #333; /* Cor da fonte para os valores */
}

/* Estiliza a seção de valores dentro do card */
.valores-cursos {
  padding: 10px 0; /* Adiciona um espaçamento acima e abaixo da seção de valores */
  border-top: 1px solid #eee; /* Adiciona uma linha separadora acima dos valores */
  margin-top: 10px; /* Espaçamento entre a descrição e a seção de valores */
}



/* PainelUsuario.css */
.originals {
  background-color: #FF7F00;

  width: auto;
  height: 100%;

}
.originals h2 {
  color: #f1f1f1;
}




.card-title {
  font-size: 16px; /* Tamanho do título */
  font-weight: bold; /* Negrito */
  margin-bottom: 15px; /* Espaçamento abaixo do título */
}

.card-text-container {
  flex-grow: 1; /* Permite que o contêiner ocupe o espaço disponível */
  overflow-y: auto; /* Habilita a rolagem vertical se o texto exceder o espaço */
  margin: 0 10px 10px 10px; /* Ajusta as margens ao redor do texto */
  max-height: 50px; /* Define uma altura máxima para o contêiner de texto */
  font-size: 11pt; /* Ajuste conforme necessário */
}

/* Estiliza a barra de rolagem (apenas para Webkit browsers) */
.programa-card .card-text-container::-webkit-scrollbar {
  width: 5px;
}

.programa-card .card-text-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.programa-card .card-text-container::-webkit-scrollbar-thumb {
  background: #888;
}

.programa-card .card-text-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.courseButton  {
  width: auto; /* Faz o botão ocupar toda a largura do card menos o padding */
  /* Espaçamento ao redor do botão */
  background-color: #FF7F00; /* Cor de fundo do botão */
  border: none; /* Remove a borda do botão */
  color: white; /* Cor do texto do botão */
  padding: 10px 0; /* Espaçamento interno do botão */
  border-radius: 5px; /* Bordas arredondadas do botão */
  cursor: pointer; /* Muda o cursor para indicar que o botão é clicável */
  transition: background-color 0.3s; /* Suaviza a transição de cores */
  align-self: center;
}
.kctdbutton  {
  width: 50%; /* Faz o botão ocupar toda a largura do card menos o padding */
  margin: 10px; /* Espaçamento ao redor do botão */
  background-color: #FF7F00; /* Cor de fundo do botão */
  border: none; /* Remove a borda do botão */
  color: white; /* Cor do texto do botão */
  padding: 10px 0; /* Espaçamento interno do botão */
  border-radius: 5px; /* Bordas arredondadas do botão */
  cursor: pointer; /* Muda o cursor para indicar que o botão é clicável */
  transition: background-color 0.3s; /* Suaviza a transição de cores */
  align-self: center;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 15px;
}

.search-bar input {
  flex-grow: 1;
  background-color: #FFFFFF;
  border: none;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
}

.search-icon {
  width: 20px;
  height: 20px;
  background-image: url('search.png'); /* Adicione o caminho correto da sua imagem */
  background-size: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .table {
    font-size: 12px;
  }

  .table td, .table th {
    padding: 8px; /* Reduz o espaçamento interno */
  }
}

/* Para dispositivos com largura até 768px */
@media (max-width: 768px) {
  .floating-cart-btn {
    position: sticky; 
    bottom: 14vh;
  }
}

@media (max-width: 768px) {
  .programa-card {
    width: auto;
  }
 


  .profile-course-card img {
    height: auto; /* Altura automática para manter a proporção da imagem */
  }
}
@media (max-width: 768px) {
  .modal-footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .modal-footer .btn {
    width: 80%;
    margin-bottom: 25px; /* Adiciona um espaçamento de 25px abaixo de cada botão */
  }

  /* Remove a margem adicional do primeiro botão (que é o último, devido ao column-reverse) para evitar espaçamento extra na parte de cima */
  .modal-footer .btn:first-child {
    margin-bottom: 0;
  }
}

