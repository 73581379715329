.container {
  padding: 20px;
}

.container h2 {
  color: #333;
  margin-bottom: 20px;
}

.container .form-group {
  margin-bottom: 15px;
}

.container .form-group label {
  font-weight: bold;
}

.container .form-group .form-control {
  border-radius: 5px;
}

.container .card {
  margin-bottom: 20px;
  border-radius: 5px;
}

.container .card .card-header {
  font-size: 20px;
  background-color: #f8f9fa;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6;
}

.container button {
  font-size: 16px;
  font-weight: bold;
}

.back {
  background-color: #E9E9E9;
  border-radius: 5px;
  padding: 15px;
}

.back1 {
  border-radius: 5px;
  padding: 15px;
  background-color: white;
  margin: 25px auto;
  width: 100%;
}

.back1 h5 {
  color: #000000;
  font-size: 15pt;
  margin-top: 5px;
}

.back1 h6 { /* Adicionado estilo para h6 */
  color: #000000;
  font-size: 15pt;
  margin-top: 5px;
  text-align: center; /* Centraliza o texto */
}

.custom-modal-content {
  max-height: 70vh;
  overflow-y: auto;
}

.btn-suus {
  margin-top: 50px;
}

.custom-modal-body {
  padding: 20px;
}

.custom-modal-body h5 {
  font-size: 15pt;
  margin-top: 5px;
}

.custom-modal-content::-webkit-scrollbar {
  width: 10px;
}

.custom-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-modal-content::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-modal-content {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

@media (max-width: 767px) {
  .form-check {
    padding-left: 15px;
  }
}

.checkbox-container {
  overflow: auto;
}