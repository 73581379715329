.sidebar{
   
    .logo-img img {
      max-width: 50px;
      max-height: auto;
    }
}

@media (max-width: 991px){
  .sidebar .nav > li.active.active-pro {
    position: relative;
    bottom: unset;
  }
}

#bodyClick {
  position: absolute;
}
