.div-certificado {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    background-color: #69717A;
    border-radius: 5px;
    padding: 15px;

}

.mensagem-validacao p {
    font-family: 'Montserrat', sans-serif;
    font-size: 15pt;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0; /* Remover espaço extra abaixo do parágrafo */
  }
  
.div-cert-text {
    font-family:'Montserrat';
    font-size: 16pt;
    text-align: center;
    color: white;
}

@media (max-width: 768px) {
    .div-cert-text{
        font-size: 12pt;
    }
}