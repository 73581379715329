.container-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .image-trabalhador {
    max-width: 100%; /* Certifica-se de que a imagem não exceda a largura do contêiner */
    height: auto; /* Mantém a proporção da imagem */
    margin-bottom: 20px; /* Espaçamento entre a imagem e o texto */
  }
  
  .h6 {
    margin: 0;
    padding: 20px 0;
  }
  
  /* Estilos adicionais para o botão, se necessário... */
  