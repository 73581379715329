/* Estilos para telas desktop e maiores */
iframe {
    width: 100%;
    max-width: 800px; /* Definir uma largura máxima para evitar que a iframe fique muito grande em telas grandes */
    height: 600px;
    display: block; /* Adicionar essa linha para centralizar o iframe horizontalmente */
    margin: 0 auto; /* Adicionar essa linha para centralizar o iframe horizontalmente */
  }
  
  /* Estilos para telas móveis */
  @media (max-width: 768px) {
    iframe {
      height: 300px; /* Reduz a altura do iframe em dispositivos móveis */
    }
  
    /* Estilos adicionais para elementos em telas móveis */
    h1, h4, h6 {
      text-align: center; /* Centralizar o texto dos cabeçalhos em telas móveis */
    }
  
   
  }
  .download-btn {
    display: none; /* Ocultar o botão em telas maiores */
  }
  
  /* Estilos para telas móveis */
  @media (max-width: 768px) {
    .download-btn {
      display: block; /* Exibir o botão em telas móveis */
      width: 100%;
      margin-top: 1rem;
      margin: 0 auto; 
    }
  }