/* Adicione este CSS em seu arquivo de estilos */

.aula-container {
  background-color: #0E1A29; /* Cor de fundo da tela */
  color: #FFFFFF; /* Cor do texto */
  font-family: 'Montserrat', sans-serif; /* Fonte Montserrat */
  height: auto; /* Altura total da tela */
  padding: 20px; /* Espaçamento interno */
}

.titulo-aula {
  font-size: 24px; /* Tamanho da fonte */
  font-weight: bold; /* Texto em negrito */

  margin-bottom: 20px; /* Espaçamento abaixo do título */
}

.player-video {
  border: none; /* Sem borda */
  border-radius: 8px; /* Borda arredondada */
  height: 500px; /* Altura do player */
  margin-bottom: 20px; /* Espaço abaixo do player */
  width: 100%; /* Largura ajustada à do container */
}
@media (max-width: 768px) {
  .player-video {
    height: 300px; /* Altura do player ajustada para telas menores */
  }
  
}
.navegacao-aulas {
  display: flex; /* Layout flexível */
  justify-content: space-between; /* Espaçamento entre os elementos */
  margin-top: 20px; /* Espaçamento acima da navegação */
}

.botao-aula, .botao-fechar {
  background-color: #15283E; /* Cor de fundo dos botões */
  border: none; /* Sem borda */
  border-radius: 5px; /* Borda arredondada */
  color: white; /* Texto branco */
  cursor: pointer; /* Cursor de ponteiro */
  font-family: 'Montserrat', sans-serif; /* Fonte Montserrat */
  padding: 10px 20px; /* Espaçamento interno */
  text-transform: uppercase; /* Texto em maiúsculas */
}

.botao-aula:hover, .botao-fechar:hover {
  background-color: #1E2A38; /* Cor de fundo ao passar o mouse */
}

.botao-aula:disabled {
  background-color: #2A3B4C; /* Cor de fundo quando desabilitado */
  cursor: not-allowed; /* Cursor de não permitido */
}

/* Media queries para responsividade */
@media (max-width: 768px) {
  .aula-container {
    padding: 10px; /* Espaçamento interno para telas menores */
  }

  .titulo-aula {
    font-size: 20px; /* Tamanho da fonte ajustado para telas menores */
  }

  .botao-aula, .botao-fechar {
    padding: 8px 16px; /* Espaçamento interno ajustado */
    font-size: 14px; /* Tamanho da fonte ajustado */
  }
}
.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 20px 0;
}
.pdf-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.botao-fechar {
margin-bottom: 15px;
}
.pdf-nav-button {
  padding: 10px 15px;
  margin: 0 10px;
  background-color: #15283E;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.pdf-nav-button:hover {
  background-color: #1E2A38;
}
.pdf-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.pdf-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.pdf-modal-close {
  display: block;
  padding: 10px 15px;
  background-color: #15283E;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.pdf-modal-close:hover {
  background-color: #1E2A38;
}
@media (max-width: 768px) {
  .navegacao-aulas {
    display: flex; /* Mantém o layout flexível */
    justify-content: center; /* Centraliza os botões horizontalmente */
    flex-direction: column; /* Organiza os botões verticalmente */
    align-items: center; /* Centraliza os botões verticalmente */
  }

  .botao-aula, .botao-fechar {
    padding: 8px 16px; /* Espaçamento interno ajustado */
    font-size: 14px; /* Tamanho da fonte ajustado */
    margin-bottom: 10px; /* Espaço entre os botões */
  }
}
