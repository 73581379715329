.cursos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: white;
}

.curso-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.curso-detalhes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.curso-nome {
  font-weight: bold;
  font-size: 1.1em;
}

.curso-imagem {
  width: 100%;
  padding-bottom: 56.25%; /* Mantém a proporção 16:9 */
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.curso-imagem img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.curso-precos {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.curso-alunos, .curso-periodo, .curso-valor-total {
  margin-bottom: 10px;
}

.curso-selecionar {
  margin-top: auto;
}

/* Estilos responsivos */
@media (max-width: 1200px) {
  .cursos-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .cursos-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .cursos-container {
    grid-template-columns: 1fr;
  }
}

.botao-comprar {
  border-radius: 5px; 
  padding: 8px 16px;
  background-color: #1E2A38; /* Define a cor de fundo do botão */
  border: none;             /* Remove a borda padrão do botão */
  color: white;             /* Define a cor do texto como branco */
}

.botao-comprar:hover {
  background-color: #14253A; /* Cor de fundo mais escura ao passar o mouse */
  cursor: pointer;            /* Indica que o botão é clicável */
}

.botao-comprar:disabled {
  background-color: #ccc; /* Cor de fundo para botão desabilitado */
  cursor: not-allowed;     /* Indica que o botão está desabilitado */
}

.curso-alunos-header {
  cursor: pointer;
  font-weight: bold;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa; /* Cor de fundo clara */
}

.curso-alunos-header:hover {
  background-color: #e2e6ea; /* Cor de fundo mais escura ao passar o mouse */
}

.curso-periodo select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff; /* Cor de fundo branca */
}

.checkout-final {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.checkout-final h2 {
  margin-bottom: 15px;
}

.cursos-selecionados {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.curso-selecionado {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
}

.curso-detalhes-checkout {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.total-compra {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.curso-valor-total {
  font-weight: bold;
}

.historico-compras {
  margin-top: 30px;
}

.historico-compras h2 {
  margin-bottom: 15px;
}

.compras-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.compra-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.compra-detalhes {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.compra-detalhes .label {
  font-weight: bold;
}

.compra-item {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  
  /* Cores de fundo alternadas */
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
}

.compra-item.aprovado {
  border-left: 5px solid #28a745; /* Verde para aprovado */
}

.compra-item.pendente {
  border-left: 5px solid #ffc107; /* Amarelo para pendente */
}

.compra-item.reprovado {
  border-left: 5px solid #dc3545; /* Vermelho para reprovado */
}

.compra-detalhes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.compra-curso-aluno,
.compra-periodo-data {
  display: flex;
  gap: 20px;
}

.compra-curso,
.compra-aluno,
.compra-periodo,
.compra-data,
.compra-status {
  display: flex;
  align-items: center; /* Alinha os ícones e labels verticalmente */
  gap: 5px;
}

.curso-selecionado {
  position: relative; /* Para posicionar o botão "x" */
  /* ... outros estilos ... */
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #dc3545; /* Cor vermelha para o "x" */
}

.remove-button:hover {
  color: #c82333; /* Cor vermelha mais escura ao passar o mouse */
}

.toast-button {
  background-color: #007bff; /* Cor azul do botão */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.compra-detalhes .label {
  font-weight: bold;
}

.compra-curso .label {
  font-size: 16px; /* Fonte maior para o nome do curso */
}

.icon-aprovado {
  color: #28a745; /* Verde */
}

.icon-pendente {
  color: #ffc107; /* Amarelo */
}

.icon-reprovado {
  color: #dc3545; /* Vermelho */
}
