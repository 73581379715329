.dashboard-container {
  padding: 2rem;
  
  .dashboard-header {
    margin-bottom: 2rem;
    background-color: #1E2A38;
    padding: 2rem;
    border-radius: 10px;
    color: white;
    
    h1 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .stat-card {
    height: 100%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-5px);
    }

    .icon-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      color: white;

      &.bg-primary { background-color: #14253A; }
      &.bg-success { background-color: #28a745; }
      &.bg-warning { background-color: #ffc107; }
      &.bg-info { background-color: #17a2b8; }
    }

    .stat-content {
      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
        color: #14253A;
      }

      p {
        margin-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .table-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-bottom: 2rem;

    .card-header {
      background-color: white;
      border-bottom: 1px solid #eee;
      padding: 1.5rem;

      h5 {
        margin: 0;
        color: #14253A;
      }
    }

    .status-legend {
      .status-item {
        margin-left: 1rem;
      }
    }

    .progress {
      height: 8px;
      border-radius: 4px;
    }

    .table {
      margin: 0;

      th {
        border-top: none;
        color: #14253A;
        font-weight: 600;
      }

      td {
        vertical-align: middle;
      }
    }
  }

  .chart-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    height: 100%;

    .card-header {
      background-color: white;
      border-bottom: 1px solid #eee;
      padding: 1.5rem;

      h5 {
        margin: 0;
        color: #14253A;
      }
    }
  }

  .timeline {
    position: relative;
    padding: 1rem 0;

    .timeline-item {
      display: flex;
      align-items: flex-start;
      padding: 1rem 0;
      border-left: 2px solid #dee2e6;
      margin-left: 1rem;
      padding-left: 2rem;
      position: relative;

      &:last-child {
        border-left: none;
      }

      .timeline-icon {
        position: absolute;
        left: -11px;
        background: white;
        padding: 2px;
      }

      .timeline-content {
        h6 {
          margin-bottom: 0.25rem;
          color: #14253A;
        }

        p {
          margin-bottom: 0.25rem;
          color: #6c757d;
        }

        small {
          color: #adb5bd;
        }
      }
    }
  }

  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 2rem;

    .nav-link {
      border: none;
      color: #6c757d;
      padding: 1rem 1.5rem;
      font-weight: 500;
      position: relative;

      &:hover {
        border: none;
        color: #14253A;
      }

      &.active {
        color: #14253A;
        background: none;
        border: none;

        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #14253A;
        }
      }
    }
  }

  .badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
  }

  .metric-card {
    background: linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%);
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.05);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 25px rgba(0,0,0,0.1);
    }

    .metric-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .trend {
        padding: 0.25rem 0.5rem;
        border-radius: 20px;
        font-size: 0.875rem;
        
        &.positive {
          background-color: rgba(40, 167, 69, 0.1);
          color: #28a745;
        }
        
        &.negative {
          background-color: rgba(220, 53, 69, 0.1);
          color: #dc3545;
        }
      }
    }

    .metric-value {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      color: #14253A;
    }

    .metric-description {
      color: #6c757d;
      font-size: 0.875rem;
    }
  }

  .enhanced-table-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.05);
    
    .table-header {
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;

      .header-title {
        h5 {
          margin: 0;
          font-size: 1.25rem;
          color: #14253A;
        }
        .subtitle {
          color: #6c757d;
          font-size: 0.875rem;
        }
      }

      .table-actions {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;
      }

      .search-filter-group {
        display: flex;
        gap: 1rem;
        align-items: center;
      }

      .status-filter-wrapper {
        min-width: 180px;
        .status-filter {
          border-radius: 8px;
          border: 1px solid #e2e8f0;
        }
      }

      .search-wrapper {
        position: relative;
        min-width: 250px;
        
        .search-icon {
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          color: #a0aec0;
        }

        input {
          padding-left: 2.5rem;
          border-radius: 8px;
          border: 1px solid #e2e8f0;
        }
      }

      .export-button {
        padding: 0.5rem 1.25rem;
        border-radius: 8px;
        background: #14253A;
        border: none;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.2s;

        &:hover {
          background: #1a365d;
          transform: translateY(-1px);
        }
      }
    }

    .custom-table {
      margin: 0;
      
      th {
        background: #f8fafc;
        padding: 1rem;
        font-weight: 600;
        color: #14253A;
        border: none;
      }

      td {
        padding: 1rem;
        border: none;
        border-bottom: 1px solid #f1f5f9;
      }

      .aluno-info {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .avatar {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #14253A;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }

      .progress-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        
        .custom-progress {
          flex: 1;
          height: 8px;
          border-radius: 4px;
        }

        .progress-label {
          min-width: 45px;
          font-weight: 500;
        }
      }

      .status-badge {
        padding: 0.5rem 1rem;
        border-radius: 20px;
        font-weight: 500;
        
        &.concluído {
          background: #def7ec;
          color: #046c4e;
        }
        
        &.em-andamento {
          background: #fef3c7;
          color: #92400e;
        }
        
        &.não-iniciado {
          background: #fee2e2;
          color: #991b1b;
        }
      }
    }
  }

  .financial-card, .completion-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.05);
    height: 100%;

    .card-header {
      background-color: #fff;
      border-bottom: 1px solid rgba(0,0,0,0.05);
      padding: 1.5rem;
    }

    .completion-stats {
      .stat-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;

        svg {
          color: #14253A;
          font-size: 1.5rem;
        }
      }
    }
  }

  .text-center.py-4 {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .export-button {
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
      background-color: #2c5282 !important;
    }
    
    &:active {
      transform: translateY(0);
      box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    }
  }

  .status-badge {
    transition: all 0.2s ease;
    
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }
  }

  .status-badge-enhanced {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.3px;
    transition: all 0.2s ease;
    
    .status-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }
    
    &.concluído {
      background: linear-gradient(145deg, #e6f7ed, #d1f7e5);
      color: #065f46;
      border: 1px solid #34d399;
      
      .status-icon {
        background-color: #059669;
        box-shadow: 0 0 0 2px rgba(5, 150, 105, 0.2);
      }
      
      &:hover {
        background: #d1f7e5;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(5, 150, 105, 0.1);
      }
    }
    
    &.em-andamento {
      background: linear-gradient(145deg, #fef3c7, #fde68a);
      color: #92400e;
      border: 1px solid #fbbf24;
      
      .status-icon {
        background-color: #d97706;
        box-shadow: 0 0 0 2px rgba(217, 119, 6, 0.2);
      }
      
      &:hover {
        background: #fde68a;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(217, 119, 6, 0.1);
      }
    }
    
    &.não-iniciado {
      background: linear-gradient(145deg, #fee2e2, #fecaca);
      color: #991b1b;
      border: 1px solid #f87171;
      
      .status-icon {
        background-color: #dc2626;
        box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.2);
      }
      
      &:hover {
        background: #fecaca;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(220, 38, 38, 0.1);
      }
    }
  }

  .text-center {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 0.5rem;
    
    .enhanced-table-card {
      margin: 0;
      border-radius: 8px;
      overflow: hidden;
      
      .card-header {
        padding: 1rem;
        
        .table-header {
          .header-title {
            h5 {
              font-size: 1.1rem;
              margin-bottom: 0.25rem;
            }
            .subtitle {
              font-size: 0.9rem;
              color: #6c757d;
            }
          }
          
          .table-actions {
            margin-top: 1rem;
            
            .search-filter-group {
              display: flex;
              flex-direction: column;
              gap: 0.75rem;
              margin-bottom: 0.75rem;
              
              .status-filter-wrapper {
                .form-select {
                  width: 100%;
                  height: 40px;
                  border-radius: 6px;
                }
              }
              
              .search-wrapper {
                position: relative;
                
                .search-icon {
                  position: absolute;
                  left: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #6c757d;
                }
                
                .form-control {
                  width: 100%;
                  height: 40px;
                  padding-left: 35px;
                  border-radius: 6px;
                }
              }
            }
            
            .export-button {
              width: 100%;
              height: 40px;
              border-radius: 6px;
            }
          }
        }
      }

      .table-responsive {
        .custom-table {
          thead {
            display: none;
          }
          
          tbody {
            tr {
              padding: 1rem;
              border-bottom: 1px solid #e2e8f0;
              display: block;
              
              td {
                padding: 0.5rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                
                &::before {
                  content: attr(data-label);
                  font-weight: 500;
                  color: #64748b;
                  flex: 0 0 120px;
                }
                
                &.text-center {
                  justify-content: space-between;
                }
                
                .aluno-info {
                  display: flex;
                  align-items: center;
                  gap: 0.75rem;
                  flex: 1;
                  
                  .avatar {
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                  }
                  
                  span {
                    font-size: 0.9rem;
                  }
                }
                
                .status-badge-enhanced {
                  margin: 0;
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
