@import "~bootstrap/scss/bootstrap";
@import 'react-toastify/dist/ReactToastify.css';

.back-prof {
  background-color: #E9E9E9;
  border-radius: 5px;
  padding: 15px;
  text-align: center; /* Alinhar texto à esquerda */
  font-family: 'Montserrat'; /* Mudar a fonte (exemplo com Arial) */
  
}
.card-btn {
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  background-color: #f5f7fa;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 15px;
  margin: 25px;
  width: 250px;
  height: 350px;

}
.coluna-estatica {
  border-radius: 5px; /* Mesmo raio de borda que .back-prof */
  background-color: #f5f7fa; /* Cor de fundo */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Sombra */
  border: none; /* Sem borda */
  padding: 35px;
}

.card-stats {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

 
}

.text-center {
  color: black;
  margin-top: 15px;
  font-family: 'montserrat';
 
}

.table {
  color: #333;
}
.tittle {
  color: black;
}

.dashboard-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  margin-bottom: 1rem;

  &:hover {
    transform: translateY(-5px);
  }

  .card-title {
    color: #0E1A29;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .display-4 {
    color: #FF7F00;
    font-weight: bold;
    font-size: 2.5rem;
  }
}

.stats-table {
  th {
    background-color: #0E1A29;
    color: white;
    font-size: 0.9rem;
  }

  td {
    font-size: 0.9rem;
  }
}

.chart-container {
  position: relative;
  height: 300px;
  margin: 1rem 0;
}

.status-tabs {
  .nav-tabs {
    border-bottom: 2px solid #FF7F00;
    
    .nav-link {
      color: #333;
      border: none;
      padding: 10px 20px;
      
      &.active {
        color: #FF7F00;
        font-weight: bold;
        border-bottom: 3px solid #FF7F00;
      }
    }
  }
}

.chart-container {
  height: 400px;
  position: relative;
  margin: 20px 0;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.dashboard-card {
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-5px);
  }
}

.dashboard-container {
  padding: 2rem;
  
  .dashboard-title {
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .dashboard-card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    .card-icon {
      font-size: 2rem;
      color: #FF7F00;
      margin-bottom: 1rem;
    }
  }
  
  .periodo-select {
    max-width: 200px;
    margin-bottom: 1rem;
  }
  
  .nav-tabs {
    border-bottom: 2px solid #FF7F00;
    
    .nav-link {
      color: #666;
      border: none;
      padding: 0.75rem 1.5rem;
      
      &.active {
        color: #FF7F00;
        font-weight: bold;
        border-bottom: 3px solid #FF7F00;
      }
    }
  }
  
  .progress {
    height: 0.8rem;
    border-radius: 1rem;
  }
  
  .table {
    th {
      background-color: #f8f9fa;
      border-top: none;
    }
    
    td {
      vertical-align: middle;
    }
  }
}
  
.dashboard-card {
  .card-title {
    color: #333;
    font-weight: 600;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #FF7F00;
    padding-bottom: 0.5rem;
  }
}

.dashboard-card {
  height: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
  }

  .display-4 {
    font-weight: bold;
    color: #FF7F00;
    margin: 0;
  }

  .card-title {
    color: #070D14;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.justify-content-center {
  .col-md-3 {
    margin: 0 1rem;
  }
}

.faturamento-card {
  .display-4 {
    font-size: 2.5rem;
    white-space: nowrap;
  }
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
}

.status-badge.concluído {
  background-color: #28a745;
  color: white;
}

.status-badge.em-andamento {
  background-color: #ffc107;
  color: black;
}

.status-badge.não-iniciado {
  background-color: #6c757d;
  color: white;
}

.enhanced-table-card {
  .table-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    
    .header-title {
      h5 {
        margin-bottom: 0.25rem;
        color: #2c3e50;
      }
      
      .subtitle {
        font-size: 0.875rem;
        color: #6c757d;
      }
    }
  }

  .status-filter {
    min-width: 150px;
    margin-right: 1rem;
    border-radius: 20px;
    padding: 0.375rem 1rem;
    border-color: #dee2e6;
    
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255,127,0,0.25);
      border-color: #FF7F00;
    }
  }

  .custom-table {
    th {
      background-color: #f8f9fa;
      border-bottom: 2px solid #dee2e6;
      color: #495057;
      font-weight: 600;
    }

    td {
      vertical-align: middle;
    }

    tr:hover {
      background-color: rgba(255,127,0,0.05);
    }
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .dashboard-title {
    margin: 0;
    color: #14253A;
    font-weight: 600;
  }

  .periodo-selector {
    position: relative;
    min-width: 200px;

    .periodo-select {
      appearance: none;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 8px 32px 8px 12px;
      width: 100%;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        border-color: #aaa;
      }

      &:focus {
        border-color: #FF7F00;
        box-shadow: 0 0 0 2px rgba(255, 127, 0, 0.2);
        outline: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #666;
      pointer-events: none;
    }
  }
}

.periodo-selector-container {
  position: relative;
  width: 250px;

  .periodo-selector-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border-color: #FF7F00;
    }

    .arrow {
      margin-left: auto;
      transition: transform 0.2s ease;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .periodo-selector-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1000;

    .anos-section {
      display: flex;
      border-bottom: 1px solid #eee;
      padding: 10px;

      .ano-item {
        padding: 5px 15px;
        cursor: pointer;
        border-radius: 4px;
        
        &:hover {
          background: #f5f5f5;
        }

        &.selected {
          background: #FF7F00;
          color: white;
        }
      }
    }

    .meses-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px;
      padding: 10px;

      .mes-item {
        padding: 8px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background: #f5f5f5;
        }

        &.selected {
          background: #FF7F00;
          color: white;
        }
      }
    }
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 15px;
}

.status-badge-enhanced {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.85em;
  transition: all 0.2s ease;
  
  .status-icon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: currentColor;
  }

  &:hover {
    transform: translateY(-1px);
  }
}

.custom-table {
  .aluno-info {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: #666;
    }
  }

  td[data-label="Status"] {
    text-align: center;
    padding: 0.75rem;
    
    .status-badge-enhanced {
      min-width: 120px;
      justify-content: center;
    }
  }
}

.Toastify__toast {
  border-radius: 8px;
  font-size: 14px;
  
  &--success {
    background: #28a745;
  }
  
  &--error {
    background: #dc3545;
  }
}

.dashboard-card {
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.status-badge {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}

.status-badge.concluído {
  background-color: #28a745;
  color: white;
}

.status-badge.em-andamento {
  background-color: #ffc107;
  color: black;
}

.distribuicao-socios ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

.distribuicao-socios li {
  margin-bottom: 5px;
  color: #666;
}
  