
.nustt {
    background-color: #14253A;
    border: none;
}
.nustt2 {
    background-color: #D9D9D9;
}

@media (max-width: 768px) {
    .nustt {
        background-color: #14253A;
       
    }
}