body {
    background-color: #070D14; /* Cor de fundo da página */
  }
  .avaliacao-inicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white;
  }
  .avaliacao-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: rgba(128, 128, 128, 0.3); /* Cinza transparente */
    border-radius: 10px; /* Bordas arredondadas */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .pergunta {
    margin-bottom: 20px;
  }
  
  .pergunta-texto {
    margin-bottom: 10px;
    font-weight: bold;
    
    color: white; /* Cor do texto para melhor leitura no fundo escuro */
  }
  
  .opcoes-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .opcao {
    display: flex;
    align-items: center;
    color: white; /* Cor do texto para melhor leitura no fundo escuro */
  }
  
  .opcao input[type="radio"] {
    margin-right: 10px;
  }
  
  .finalizar-btn {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background-color: #FF7F00; /* Cor do botão */
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .finalizar-btn:hover {
    background-color: #cc6600; /* Cor do botão ao passar o mouse */
  }
  .status-container {
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: #14253A;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    z-index: 1000; /* Garante que fique acima de outros elementos */
  }
  
  .mensagem-erro {
    color: red;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .tentativas, .temporizador {
    margin-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .avaliacao-container {
      padding: 10px;
      margin: 15px;
    }
  }
  /* Estilos para radio buttons */
.opcao input[type="radio"] {
  max-width: 15px; /* Define uma largura fixa para o radio button */
  max-height: 15px; /* Define uma altura fixa para o radio button */
  margin-right: 10px;
}



/* Estilos para telas mobile */
@media (max-width: 768px) {
  .opcao {
    flex-direction: column; /* Exibe o texto abaixo do radio button */
    align-items: flex-start;
  }

  .opcao input[type="radio"] {
    margin-bottom: 5px; /* Adiciona espaço entre o radio button e o texto */
  }
}