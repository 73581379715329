/* PainelUsuario.css */
.user-profile-row {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens quebrem em várias linhas */
  justify-content: center;
}

.curso-item1 {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px; /* Largura do card */
  display: flex;
  flex-direction: column;
}

.curso-detalhes1 {
 
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Permite que a seção de detalhes ocupe o espaço disponível */
}

.curso-imagem1 {
  width: 100%;
  height: 0; /* Definir altura como 0 */
  padding-bottom: 56.25%; /* Proporção 16:9 (9 / 16 * 100%) */
  position: relative; /* Para posicionar a imagem dentro do container */
  overflow: hidden; /* Para cortar o excesso da imagem */
}

.curso-imagem1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.curso-nome1 {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.curso-periodo1,
.curso-acesso1,
.curso-progresso1 {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.curso-periodo1 svg,
.curso-acesso1 svg,
.curso-progresso1 svg {
  margin-right: 5px;
}

.curso-acessar1 {
  padding: 10px;
  text-align: center;
}

.botao-acessar1 {
  background-color: #15283E;
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Faz o botão ocupar toda a largura */
}

.botao-acessar1:hover {
  background-color: #194267;
}


 .programa-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Espaço entre os itens, mantendo o botão sempre no fundo */
  width: 300px; /* Largura fixa para cada card */
  height: auto; /* Altura automática para se ajustar ao conteúdo */
  margin-bottom: 20px;
  margin: 15px;
}

.programa-card .card-body {
  display: flex;
  flex-direction: column;
  height: 100%; /* Faz o conteúdo do card ocupar todo o espaço disponível */
}

.programa-card img {
  width: 100%; /* Assegura que a imagem cubra toda a largura do card */
  height: 100%x; /* Altura fixa para a imagem */
  object-fit: cover; /* Faz com que a imagem preencha o espaço disponível sem distorcer */
}

.programa-card .card-title {
  font-size: 18px; /* Tamanho do título */
  font-weight: bold; /* Negrito */
  margin-bottom: 15px; /* Espaçamento abaixo do título */
}

.programa-card .card-text-container {
  flex-grow: 1; /* Faz a descrição ocupar todo o espaço vertical disponível */
  overflow-y: auto; /* Mostra a barra de rolagem vertical se necessário */
  margin: 0 10px 15px 10px; /* Margens ao redor da descrição */
  height: 100px; /* Altura fixa para a descrição */
}

/* Estiliza a barra de rolagem (apenas para Webkit browsers) */
.programa-card .card-text-container::-webkit-scrollbar {
  width: 5px;
}

.programa-card .card-text-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.programa-card .card-text-container::-webkit-scrollbar-thumb {
  background: #888;
}

.programa-card .card-text-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.img-catalogo {
  width: 100%;
}
.back-inventario {
  background-color: #69717A;
  border-radius: 5px;
}
.back-aluno {
  background-color: #1E2A38;
  border-radius: 5px;
  padding: 15px;
}
.programa-card .courseButton  {
  width: calc(100% - 20px); /* Faz o botão ocupar toda a largura do card menos o padding */
  margin: 10px; /* Espaçamento ao redor do botão */
  background-color: #15283E; /* Cor de fundo do botão */
  border: none; /* Remove a borda do botão */
  color: white; /* Cor do texto do botão */
  padding: 10px 0; /* Espaçamento interno do botão */
  border-radius: 5px; /* Bordas arredondadas do botão */
  cursor: pointer; /* Muda o cursor para indicar que o botão é clicável */
  transition: background-color 0.3s; /* Suaviza a transição de cores */
  align-self: center;
}

.curso-concluido-certificado {
  background-color: #f9f9f9; /* Cor de fundo suave para destacar */
  border-left: 4px solid green; /* Barra lateral para destaque */
  padding: 10px; /* Espaçamento interno */
  margin: 10px 0; /* Margem acima e abaixo */
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 14px; /* Tamanho da fonte */
  color: #333; /* Cor da fonte */
}

.kctdbutton  {
  width: 50%; /* Faz o botão ocupar toda a largura do card menos o padding */
  margin: 10px; /* Espaçamento ao redor do botão */
  background-color: #15283E; /* Cor de fundo do botão */
  border: none; /* Remove a borda do botão */
  color: white; /* Cor do texto do botão */
  padding: 10px 0; /* Espaçamento interno do botão */
  border-radius: 5px; /* Bordas arredondadas do botão */
  cursor: pointer; /* Muda o cursor para indicar que o botão é clicável */
  transition: background-color 0.3s; /* Suaviza a transição de cores */
  align-self: center;
}
.welcome-message {
  text-align: center; /* Alinhar texto à esquerda */
  font-family: 'Montserrat'; /* Mudar a fonte (exemplo com Arial) */
  color: White;

}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 15px;
}

.search-bar input {
  flex-grow: 1;
  background-color: #F7F7F8;
  border: none;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
}

.search-icon {
  width: 20px;
  height: 20px;
  background-image: url('search.png'); /* Adicione o caminho correto da sua imagem */
  background-size: cover;
  cursor: pointer;
}

/* PainelUsuario.css */

@media (max-width: 768px) {
  .user-profile-row .programa-card {
    width: 90%; /* Faz o card ocupar a maior parte da tela */
    margin: 10px auto; /* Adiciona margem acima e abaixo, centraliza horizontalmente */
  }

  .user-profile-row {
    justify-content: center; /* Centraliza os cards horizontalmente */
  }

  .profile-course-card img {
    height: auto; /* Altura automática para manter a proporção da imagem */
  }
}

  /* Estilos adicionais para melhorar a aparência em dispositivos móveis */
  /* ... */
